import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const Gerentes = loadable(() => import('@screens/escala/Gerentes'));

function Index(props) {
  return (
    <Layout {...props}>
      <Gerentes {...props} />
    </Layout>
  );
}

export default Index;
